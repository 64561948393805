import { axios, API_ROUTES } from "@/config";

export default {
  async find(params) {
    let result = await axios.get(`${API_ROUTES.profile.get}`, {
      params: params,
    });

    return result;
  },
};
